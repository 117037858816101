<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <div class="content">
        <div class="logo-box">
          <img src="https://www.achencybe.com/img/pc/trademark.png">
        </div>
        <div class="code-box">
            <div style="margin-top:20px;font-size:30px;">页面正在维护中，精彩即将呈现。</div>
            <div style="margin-top:10px;font-size:30px;">The page is under maintenance,We will be back soon.</div>
        </div>
      </div>
      <zui-footer />
    </div>
  </Page>
</template>

<script>

import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
export default {
  name: "footerDetail",
  components: { Page, ZuiHeader, ZuiFooter },
  computed:{
    type(){
      return this.$route.query.type
    }
  }
};
</script>

<style scoped lang="scss">
.content{
  width:54%;
  margin:200px auto 200px;
  box-shadow: #0c0c0c -5px 9px 17px 4px;
  .logo-box{
    width:100%;
    padding:100px 0 20px 0;
    background-color:#121212;
    img{
      width:20%;
    }
  }
  .code-box{
    background-color:#121212;
    width:100%;
    padding:3% 0 100px 0;
    height:500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      color: #ffffff;
    }
  }
}
</style>