var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "logo-box" }, [
            _c("img", {
              attrs: { src: "https://www.achencybe.com/img/pc/trademark.png" },
            }),
          ]),
          _c("div", { staticClass: "code-box" }, [
            _c(
              "div",
              { staticStyle: { "margin-top": "20px", "font-size": "30px" } },
              [_vm._v("页面正在维护中，精彩即将呈现。")]
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "10px", "font-size": "30px" } },
              [_vm._v("The page is under maintenance,We will be back soon.")]
            ),
          ]),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }